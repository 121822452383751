import request from "@/utils/request";

export function fetchCategorieEx(query) {
  return request({
    url: "/category-expenses/fetch",
    method: "get",
    params: query,
  });
}

export function fetchCategorieList() {
  return request({
    url: "/category-expenses/list",
    method: "get",
  });
}

export function createCategorie(data) {
  return request({
    url: "/category-expenses",
    method: "post",
    data,
  });
}

export function updateCategorie(data, id) {
  return request({
    url: `/category-expenses/${id}`,
    method: "put",
    data,
  });
}

// transactions

export function nextExpenseNo() {
  return request({
    url: "/expenses/next-numero",
    method: "get",
  });
}

export function fetchExpense(query) {
  return request({
    url: "/expenses/fetch",
    method: "get",
    params: query,
  });
}
export function createExpense(data) {
  return request({
    url: "/expenses",
    method: "post",
    data,
  });
}

export function cancelExpense(numero) {
  return request({
    url: `/expenses/${numero}`,
    method: "delete",
  });
}

export function paidExpense(data) {
  return request({
    url: `/expenses/paid`,
    method: "post",
    data,
  });
}

export function getExpense(numero) {
  return request({
    url: `/expenses/${numero}`,
    method: "get",
  });
}

export function getTotalExpense(query) {
  return request({
    url: `/expenses/total`,
    method: "get",
    params: query,
  });
}

export function getTotalOpendExpense() {
  return request({
    url: `/expenses/total-opend`,
    method: "get",
  });
}