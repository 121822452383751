<template>
  <div class="flex flex-col font-Inter p-6">
    <div class="bg-white border px-6 py-5">
      <div class="flex flex-row mb-4 items-center">
        <el-button
          class="mr-2"
          @click="handNewExpense"
          type="primary"
          icon="el-icon-plus"
          >créer une depense</el-button
        >
        <el-input
          class="mr-4 w-2/6"
          placeholder="discription"
          v-model="listQuery.title"
          @keydown.native.enter="fetchData()"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>

        <el-select
          class="mr-4 w-1/6"
          v-model="listQuery.category"
          placeholder="Categorie"
          clearable
          filterable
          @change="fetchData()"
        >
          <el-option
            v-for="item in categories"
            :key="item.id"
            :label="`${item.name}`"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <el-date-picker
          class="mr-2"
          v-model="listQuery.start"
          type="date"
          value-format="yyyy-MM-dd"
          format="dd/MM/yyyy"
          placeholder="Date debut"
        >
        </el-date-picker>
        <el-date-picker
          class="mr-2 w-1/2"
          v-model="listQuery.end"
          type="date"
          value-format="yyyy-MM-dd"
          format="dd/MM/yyyy"
          placeholder="Date fin"
        >
        </el-date-picker>
        <el-button @click="fetchData()" icon="el-icon-search"
          >recherche</el-button
        >
      </div>

      <div class="customer-white">
        <div class="flex">
          <div class="my-5 font-semibold mr-5">
            Total des depenses :
            {{ total | moneyFilter }} {{user.currency_code}}
          </div>

          <div class="my-5 text-blue font-semibold">
            Total des dépenses non payé :
            {{ totalOpend | moneyFilter }} {{user.currency_code}}
          </div>
        </div>
        <skeleton-list :row="8" v-if="listLoading" />
        <el-table v-else :data="items" style="width: 100%">
          <el-table-column label="N°DEPENSE" width="150">
            <template slot-scope="{ row }">
              <span class="text-blue-500">{{ row.numero }}</span>
            </template>
          </el-table-column>
          <el-table-column label="DATE" width="130">
            <template slot-scope="{ row }">
              {{ row.date }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="STATUT" width="120">
            <template slot-scope="{ row }">
              <Status :name="row.status" />
            </template>
          </el-table-column>
          <el-table-column label="CATEGORIE">
            <template slot-scope="{ row }">
              {{ row.category_name }}
            </template>
          </el-table-column>
          <el-table-column label="PAYER PAR">
            <template slot-scope="{ row }">
              <div class="flex flex-col">
                <span>{{ row.account_name }}</span>
                <!--   <span class="text-gray-300 text-sm">{{
                row.account_numero
              }}</span> -->
              </div>
            </template>
          </el-table-column>

          <el-table-column label="INTITULÉ" min-width="130">
            <template slot-scope="{ row }">
              {{ row.describe }}
            </template>
          </el-table-column>
          <el-table-column label="MONTANT " width="180" align="right">
            <template slot-scope="{ row }">
              <div class="flex flex-col">
                <span>{{ row.amount | moneyFilter }} {{user.currency_code}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Actions" align="right" width="150">
            <template slot-scope="scope">
              <el-tooltip
                v-if="scope.row.status === 'OPEND'"
                class="item"
                effect="dark"
                content="Payer cette depense"
                placement="bottom-end"
              >
                <el-button
                  type="primary"
                  circle
                  icon="el-icon-plus"
                  size="mini"
                  @click="handlePaid(scope.row.numero)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="Annuler la depense"
                placement="bottom-end"
              >
                <el-button
                  type="danger"
                  circle
                  size="mini"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row.numero, scope.$index)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex justify-end">
        <pagination
          v-show="listQuery.page > 1"
          :total="listQuery.total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.size"
          @pagination="fetchData()"
        />
      </div>

      <!-- Transfert en facture -->
      <el-dialog
        title="Ajouter une depense"
        :visible.sync="dialogVisibleExpense"
        width="40%"
        top="9vh"
        :close-on-click-modal="false"
      >
        <el-form
          :model="expense"
          :rules="rule"
          ref="expenseForm"
          :label-position="labelPosition"
        >
          <div class="flex">
            <el-form-item class="mr-2" prop="date" label="Date">
              <el-date-picker
                v-model="expense.date"
                type="date"
                placeholder="jj mmm. aaaa"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item class="flex-1" prop="numero" label="Numéro">
              <el-input v-model="expense.numero"> </el-input>
            </el-form-item>
          </div>
          <el-form-item prop="category_id" label="Categorie">
            <el-select
              style="width: 100%"
              v-model="expense.category_id"
              placeholder="Choisir"
              clearable
              filterable
            >
              <el-option
                v-for="item in categories"
                :key="item.id"
                :label="`${item.name}`"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="status" label="Statut">
            <el-radio-group v-model="expense.status" @change="handleStatus">
              <el-radio-button label="OPEND">Non payé</el-radio-button>
              <el-radio-button label="PAID">Payé</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <div class="flex">
            <el-form-item
              v-if="expense.status === 'PAID'"
              class="mr-2"
              label="Payer par"
              prop="account_numero"
            >
              <el-select
                style="width: 100%"
                v-model="expense.account_numero"
                placeholder="Choisir le compte"
                clearable
                filterable
                @change="handleAccount"
              >
                <el-option
                  v-for="item in accounts"
                  :key="item.account_id"
                  :label="`${item.account_name}`"
                  :value="item.numero"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="flex-1" prop="amount" label="Montant">
              <el-input-number
                style="width: 50%"
                :controls="false"
                v-model="expense.amount"
              />
            </el-form-item>
          </div>

          <el-form-item
            prop="describe"
            label="Note sur la depense"
            class="flex-1"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 4 }"
              placeholder="Ecrire ici"
              v-model="expense.describe"
            />
          </el-form-item>
        </el-form>

        <div slot="footer" class="border-t pt-2 dialog-footer">
          <el-button
            type="primary"
            :loading="loadingAdd"
            :disabled="expense.amount <= 0"
            @click="createData()"
            >Enregistrer</el-button
          >
        </div>
      </el-dialog>
      <!-- Payment -->
      <el-dialog
        title="Confirmer le paiement"
        :visible.sync="dialogVisibleExpensePaid"
        width="40%"
        top="9vh"
        :close-on-click-modal="false"
      >
        <el-form
          :model="expensePaid"
          :rules="rule"
          ref="expensePaidForm"
          :label-position="labelPosition"
        >
          <el-form-item class="mr-2" label="Payer par" prop="account_numero">
            <el-select
              style="width: 100%"
              v-model="expensePaid.account_numero"
              placeholder="Choisir le compte"
              clearable
              filterable
              @change="handleAccountPaid"
            >
              <el-option
                v-for="item in accounts"
                :key="item.account_id"
                :label="`${item.account_name}`"
                :value="item.numero"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <div slot="footer" class="border-t pt-2 dialog-footer">
          <el-button
            type="primary"
            :loading="loadingAdd"
            :disabled="expensePaid.account_numero.length === 0"
            @click="createPaymentExpense()"
            >Enregistrer</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import {
  fetchExpense,
  fetchCategorieList,
  nextExpenseNo,
  createExpense,
  cancelExpense,
  paidExpense,
} from "@/api/expense";
import { fetchAccounts } from "@/api/account";
import { toThousandFilter } from "@/Filters";
import { parseDate, formatDate } from "@/utils";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";
import {mapGetters} from "vuex";

export default {
  name: "LIST-EXPENSE",
  components: {
    Pagination,
    Status,
    SkeletonList,
  },
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      activeName: "CATEGORY",
      items: [],
      accounts: [],
      categories: [],
      listLoading: false,
      dialogVisibleExpense: false,
      labelPosition: "top",
      dialogVisibleExpensePaid: false,
      loadingAdd: false,
      totalOpend: 0,
      total: 0,
      expensePaid: {
        account_numero: "",
        account_name: "",
        numero: "",
      },
      expense: {
        numero: "",
        amount: 0,
        status: "OPEND",
        describe: "",
        category_id: null,
        account_numero: "",
        account_name: "",
        date: formatDate(new Date()),
      },
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        title: undefined,
        start: undefined,
        end: undefined,
        category: undefined,
        sort: "desc",
        sortDir: "createdAt",
      },
      rule: {
        status: [
          {
            required: true,
            message: "Le champs est obligatoire",
            trigger: "change",
          },
        ],
        numero: [
          {
            required: true,
            message: "Le champs est obligatoire",
            trigger: "blur",
          },
        ],
        date: [
          {
            required: true,
            message: "La date est obligatoire",
            trigger: "change",
          },
        ],
        category_id: [
          {
            required: true,
            message: "La categorie est obligatoire",
            trigger: "change",
          },
        ],
        account_numero: [
          {
            required: true,
            message: "Le compte est obligatoire",
            trigger: "change",
          },
        ],
        amount: [
          {
            required: true,
            message: "Le montant est obligatoire",
            trigger: "blur",
          },
        ],
        describe: [
          {
            required: true,
            message: "Le discription est obligatoire",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
      ...mapGetters(["user"]),
  },
  mounted() {
    this.fetchData();
    this.fetchCategories();
    this.getAccounts();
    const params = this.$route.params;
    if (params.newexpense) {
      this.dialogVisibleExpense = true;
    }
  },
  methods: {
    createData() {
      this.$refs["expenseForm"].validate((valid) => {
        if (valid) {
          this.loadingAdd = true;
          createExpense(this.expense).then((res) => {
            setTimeout(() => {
              this.items.unshift(Object.assign({}, res.data));
              this.loadingAdd = false;
              if (this.expense.status === "OPEND") {
                this.totalOpend += this.expense.amount;
              }
              this.dialogVisibleExpense = false;
              this.$message({
                message: "La depense enregistrée.",
                type: "success",
              });
            }, 1.5 * 1000);
          });
        }
      });
    },
    fetchData() {
      this.listLoading = true;
      fetchExpense(this.listQuery).then((res) => {
        setTimeout(() => {
          this.items = res.data.items;
          this.listQuery.total = parseInt(res.data.total_items);
          this.listQuery.page = parseInt(res.data.current_page) + 1;
          this.totalOpend = res.data.total_opend;
          this.total = res.data.total;
          this.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    async fetchCategories() {
      await fetchCategorieList().then((res) => {
        this.categories = res.data;
      });
    },
    async getNextNoExpense() {
      await nextExpenseNo().then((res) => {
        console.log(res.data.numero);
        this.expense.numero = res.data.numero;
      });
    },
    handNewExpense() {
      //  this.fetchCategories();
      this.resetExpense();
      this.getNextNoExpense();
      this.dialogStatus = "create";
      this.dialogVisibleExpense = true;
      this.$nextTick(() => {
        this.$refs["expenseForm"].clearValidate();
      });
    },

    async getAccounts() {
      await fetchAccounts()
        .then((res) => {
          this.accounts = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleStatus(e) {
      if (e === "OPEND") {
        this.expense.account_numero = "";
      }
    },
    handleAccount(numero) {
      const acc = this.accounts.find((el) => el.numero === numero);
      this.expense.account_name = acc.account_name;
    },
    handleAccountPaid(numero) {
      const acc = this.accounts.find((el) => el.numero === numero);
      this.expensePaid.account_name = acc.account_name;
    },

    handleDelete(numero, index) {
      this.$confirm(
        "Vous souhaitez vraimment annuler le document ?",
        `Depense N° ${numero}`,
        {
          distinguishCancelAndClose: true,
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Supprimer",
          showClose: false,
          type: "warning",
          closeOnClickModal: false,
          cancelButtonText: "Annuler",
        }
      ).then(() => {
        cancelExpense(numero)
          .then(() => {
            this.items.splice(index, 1);
            this.$message({
              type: "success",
              message: " Le paiement supprimmer avec success",
            });
          })
          .catch((err) => {
            if (err.data.status === 490) {
              this.$message({
                message: err.data.message,
                type: "warning",
              });
            } else {
              this.$message({
                message: "Erreur serveur",
                type: "danger",
              });
            }
          });
      });
    },
    resetExpense() {
      this.expense = {
        numero: "",
        amount: 0,
        status: "OPEND",
        describe: "",
        category_id: null,
        account_numero: "",
        account_name: "",
        date: formatDate(new Date()),
      };
    },
    handlePaid(numero) {
      this.getAccounts();

      this.expensePaid = {
        account_numero: "",
        account_name: "",
        numero: numero,
      };

      this.dialogVisibleExpensePaid = true;
    },
    createPaymentExpense() {
      this.loadingAdd = true;
      paidExpense(this.expensePaid)
        .then(() => {
          setTimeout(() => {
            this.loadingAdd = false;
            this.dialogVisibleExpensePaid = false;
            this.fetchData();
          }, 1.5 * 1000);
        })
        .catch((err) => {
          this.loadingBill = false;
          if (err.data.status === 400) {
            this.$message({
              message:
                "Veuillez renseigner les informations obligatoire et les articles",
              type: "warning",
            });
          } else if (err.data.status === 490) {
            this.$message({
              message: err.data.message,
              type: "warning",
            });
          } else {
            this.$message({
              message: "Erreur serveur",
              type: "danger",
            });
          }
        });
    },
  },
};
</script>

<style></style>
