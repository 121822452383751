<template>
  <div class="flex flex-col font-Inter p-6 px-45">
    <div class="bg-white border px-6 py-5">
      <div class="flex justify-end mb-4">
        <el-button
          class="mr-2"
          @click="handNewCategorie"
          type="primary"
          icon="el-icon-circle-plus"
          >Créer une categorie</el-button
        >
        <el-input
          placeholder="Rechercher"
          v-model="listQuery.name"
          @keydown.native.enter="fetchData()"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>

      <div class="customer-white">
        <skeleton-list :row="8" v-if="listLoading" />
        <el-table v-else :data="items" style="width: 100%">
          <el-table-column label="INTITULÉ" min-width="180">
            <template slot-scope="{ row }">
              {{ row.name }}
            </template>
          </el-table-column>
          <el-table-column
            label="TOTAL DEPENSE EN COURS"
            width="250"
            align="right"
          >
            <template slot-scope="{ row }">
              <span>{{ row.total_opend | moneyFilter }} {{user.currency_code}} </span>
            </template>
          </el-table-column>
          <!--  <el-table-column label="TOTAL PAYÉ" width="180" align="right">
            <template slot-scope="{ row }">
              <span class="">{{ row.totalPAID | moneyFilter }} {{user.currency_code}}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="ACTION" align="right" width="150">
            <template slot-scope="{ row }">
              <el-button
                circle
                size="mini"
                icon="el-icon-edit"
                @click="handleUpdate(row)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex justify-end">
        <pagination
          v-show="listQuery.page > 1"
          :total="listQuery.total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.size"
          @pagination="fetchData()"
        />
      </div>

      <el-dialog
        :title="
          dialogStatus === 'create'
            ? 'Ajouter une categorie'
            : 'Modifier une categorie'
        "
        :visible.sync="dialogVisibleCategory"
        width="35%"
        top="9vh"
        :close-on-click-modal="false"
      >
        <el-form
          :model="category"
          :rules="ruleCategorie"
          ref="categorieForm"
          :label-position="labelPosition"
        >
          <el-form-item class="flex-1 mr-2" prop="name" label="Libélle">
            <el-input v-model="category.name"> </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="border-t pt-2 dialog-footer">
          <el-button
            type="primary"
            :loading="loadingCategory"
            @click="dialogStatus === 'create' ? createData() : updateData()"
            >Enregistrer</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import {
  createCategorie,
  fetchCategorieEx,
  updateCategorie,
} from "@/api/expense";
import { toThousandFilter } from "@/Filters";
import { parseDate } from "@/utils";
import SkeletonList from "@/components/Skeleton/list";
import {mapGetters} from "vuex";

export default {
  name: "CATEGORY-EXPENSE",
  components: {
    Pagination,
    SkeletonList,
  },
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      activeName: "CATEGORY",
      transactions: [],
      items: [],
      listLoading: false,
      dialogVisibleCategory: false,
      labelPosition: "top",
      loadingCategory: false,
      dialogStatus: "create",
      category: {
        id: undefined,
        name: "",
      },
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        name: undefined,
        sort: "desc",
        sortDir: "createdAt",
      },
      ruleCategorie: {
        name: [
          {
            required: true,
            message: "Le champs est obligatoire",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    activeName(val) {
      this.$router.push(`${this.$route.path}?tab=${val}`);
    },
  },
  created() {
    const tab = this.$route.query.tab;

    if (tab) {
      this.activeName = tab;
    }
  },
    computed: {
        ...mapGetters(["user"]),
    },
  mounted() {
    this.fetchData();
  },
  methods: {
    createData() {
      this.$refs["categorieForm"].validate((valid) => {
        if (valid) {
          this.loadingCategory = true;
          createCategorie(this.category).then(() => {
            setTimeout(() => {
              this.dialogVisibleCategory = false;
              this.loadingCategory = false;
              this.fetchData();
            }, 1.5 * 1000);
          });
        }
      });
    },
    async fetchData() {
      this.listLoading = true;
      await fetchCategorieEx(this.listQuery).then((res) => {
        setTimeout(() => {
          this.items = res.data.items;
          this.listQuery.total = parseInt(res.data.total_items);
          this.listQuery.page = parseInt(res.data.current_page) + 1;
          this.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    handNewCategorie() {
      this.dialogStatus = "create";
      this.dialogVisibleCategory = true;
      this.$nextTick(() => {
        this.$refs["categorieForm"].clearValidate();
      });
    },
    handleUpdate(row) {
      this.dialogStatus = "edit";
      this.category = { id: row.id, name: row.name };
      this.dialogVisibleCategory = true;
      this.$nextTick(() => {
        this.$refs["categorieForm"].clearValidate();
      });
    },

    updateData() {
      this.$refs["categorieForm"].validate((valid) => {
        if (valid) {
          this.loadingCategory = true;
          updateCategorie(this.category, this.category.id).then(() => {
            setTimeout(() => {
              const index = this.items.findIndex(
                (v) => v.id === this.category.id
              );
              this.items.splice(index, 1, {
                id: this.category.id,
                name: this.category.name,
                totalInprogress: 0,
                totalPAID: 0,
              });
              this.dialogVisibleCategory = false;
              this.loadingCategory = false;
              this.$message({
                showClose: true,
                message: "Modifier avec succès.",
                type: "success",
              });
            }, 1.5 * 1000);
          });
        }
      });
    },
    /*
    handleDelete(row, index) {
      this.$notify({
        title: "Success",
        message: "Delete Successfully",
        type: "success",
        duration: 2000,
      });
      this.list.splice(index, 1);
    }, */
  },
};
</script>

<style></style>
