<template>
  <div>
    <PageHeader>
      <div class="text-gray-900 text-2xl font-semibold py-2">
        Gestion des depenses
      </div>
    </PageHeader>
    <div class="flex flex-col">
      <div class="bg-white h-12 pt-2 px-6 pb-0">
        <el-tabs v-model="activeName">
          <el-tab-pane label="CATEGORIES DE DEPENSE" name="CATEGORY">
          </el-tab-pane>
          <el-tab-pane name="EXPENSE" label="LISTES DES DEPENSES">
          </el-tab-pane>
        </el-tabs>
      </div>
      <keep-alive class="my-3">
        <category v-if="activeName === 'CATEGORY'" class="px-6" />
        <expense v-if="activeName === 'EXPENSE'" class="px-6" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Category from "./components/Category.vue";
import Expense from "./components/Transaction.vue";
export default {
  name: "DEPENSE",
  components: {
    PageHeader,
    Category,
    Expense,
  },
  watch: {
    activeName(val) {
      this.$router.push(`${this.$route.path}?tab=${val}`);
    },
  },
  created() {
    // init the default selected tab
    const tab = this.$route.query.tab;
    if (tab) {
      this.activeName = tab;
    }
  },
  data() {
    return {
      activeName: "CATEGORY",
    };
  },
};
</script>

<style></style>
